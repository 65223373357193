import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe1.css'
import StopMotion from "../images/stopmotion.mp4"

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import cardfoto1 from "../images/steak-card.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/Bifemanteiga-card.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaA = () => (
  <Layout>
    <SEO title="Joe's Butcher - Receita 1" />

    <div id="rec-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo aller-display">
            Maminha no Forno com Molho BBQ Caseiro
        </div>
        <div className="desc sneak-regular">
        O molho BBQ agridoce é a combinação perfeita de sabores que contrasta com a gordura da carne.

                </div>
        </div>
    </div>
    <div id="rec-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de Maminha
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            200ml
                        </div>
                        <div className="text sneak-regular">  
                         Ketchup
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cebola
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            20ml
                        </div>
                        <div className="text sneak-regular">  
                          Vinagre
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2
                        </div>
                        <div className="text sneak-regular">  
                         Colheres de sopa de Mostarda
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                        Dente de Alho
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3
                        </div>
                        <div className="text sneak-regular">  
                         Colheres de sopa Açucar Amarelo
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                        3
                        </div>
                        <div className="text sneak-regular">  
                        Colheres de Molho Inglês
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                          100ml
                        </div>
                        <div className="text sneak-regular">  
                        Sumo de Limão
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                          100ml
                        </div>
                        <div className="text sneak-regular">  
                       Água
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                          Q/B
                        </div>
                        <div className="text sneak-regular">  
                      Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                         Q/B
                        </div>
                        <div className="text sneak-regular">  
                    PIMENTA
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                          30ml
                        </div>
                        <div className="text sneak-regular">  
                      Azeite
                        </div>
                        
                    </div>
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Retire a peça de carne da embalagem 20 minutos antes da confeção.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa frigideira bem quente, adicione a maminha previamente temperada com sal e pimenta e frite 2 a 3 minutos de cada lado para selar.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Coloque no forno previamente aquecido a 180º.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Para o molho, numa panela salteie a cebola picada durante 2 minutos, adicione o alho picado e salteie mais um minuto, mexendo sempre.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 - 
                        </div>
                        <div className="text sneak-regular">  
                        Adicione os restantes ingredientes, tempere e misture muito bem com uma colher.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            6 - 
                        </div>
                        <div className="text sneak-regular">  
                        Deixe cozinhar a lume brando mexendo de vez em quando, até o molho começar a engrossar.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            7 - 
                        </div>
                        <div className="text sneak-regular">  
                        Retire a carne do forno após atingir o ponto desejado e deixe repousar 5 minutos.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            8 - 
                        </div>
                        <div className="text sneak-regular">  
                        Fatie a carne, sirva com o molho e acompanhamento a seu gosto.
                        </div>
                        
                    </div>
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="rec4-section-3">
        <div className="wrapper">
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={StopMotion} type="video/mp4" />
         </video>

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            456,1
                        </div>
                        <div className="titulo sneak-regular">
                            41,4
                        </div>
                        <div className="titulo sneak-regular">
                           8,2
                        </div>
                        <div className="titulo sneak-regular">
                         49,5
                        </div>
                        <div className="titulo sneak-regular">
                         47,4
                        </div>
                        <div className="titulo sneak-regular">
                          85,4
                        </div>
                        <div className="titulo sneak-regular">
                        2,6
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
    {/* <div id="rec-section-3">
        <div className="wrapper">
            <div className="left-col">
            <img src={cardfoto1} alt="Logo" />

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div> */}


  </Layout>
)


export default ReceitaA
